import firebase from "firebase";
const firebaseConfig = {
    apiKey: "AIzaSyBEegVn6M4HPEvjDdjTf9e5511_GESUlqE",
  authDomain: "quarter-604b8.firebaseapp.com",
  projectId: "quarter-604b8",
  storageBucket: "quarter-604b8.appspot.com",
  messagingSenderId: "1037131125433",
  appId: "1:1037131125433:web:d71f88211c19a9014f0168"
  };
  
  // Initialize Firebase
  firebase.initializeApp(firebaseConfig);
  export default firebase;


//   import firebase from 'firebase';

//  const config = {
//     apiKey: "AIzaSyDveqfNpytrXEA4vekMTistUkeTxTiddlc",
//     authDomain: "nowway-5bec2.firebaseapp.com",
//     projectId: "nowway-5bec2",
//     storageBucket: "nowway-5bec2.appspot.com",
//     messagingSenderId: "423215886530",
//     appId: "1:423215886530:web:8f076a86f2be9955fe4b5b"
// };

// firebase.initializeApp(config);

// export default firebase;
