import React, { useEffect, useState } from 'react';
import Navbar from './global-components/navbar-v4';
import PageHeader from './global-components/new-page-header';
import ServiceDetails from './section-components/service-details';
import CallToActionV1 from './section-components/call-to-action-v1';
import Footer from './global-components/footer';
import { GetSubService } from '../components/apiActions/index';
import {Helmet} from "react-helmet";
import { useParams } from 'react-router-dom';
const Service_Details = (props) => {
    const params = new URLSearchParams(props.location.search);
    const { code } =useParams()
    const EditText = params.get('edit');
    const ser_image = params.get('image');
    const ser_name = params.get('ser_name');
    const service =params.get('service')
    const [sub_services, setSub_services] = useState();
    const [meta, setMeta] = useState();

    useEffect(() => {
        GetSubService(code).then((response) => {
            console.log("Service Details", response)
            setMeta(response.metaData);
            setSub_services(response.Response)
        })
    }, [])

    return <div>
        <Helmet>
            <title>{`${meta && meta.title? meta.title : "Now Way"}`}</title>
            <meta name="description" content={`${meta && meta.meta_description? meta.meta_description : "Now Way"}`} />
            <meta name="keywords" content={`${meta && meta.meta_keyword? meta.meta_keyword : "house for rental in Chennai"}`}></meta>
            <meta itemprop="image" src={`${meta && meta.image? meta.image : "assets/img/logonow.png"}`}  />
            <meta name="og:title" content={`${meta && meta.title? meta.title : "Now Way"}`} />
            <meta name="og:description" content={`${meta && meta.meta_description? meta.meta_description : "Now Way"}`} />
            <meta name="og:keywords" content={`${meta && meta.meta_keyword? meta.meta_keyword : "house for rental in Chennai"}`} />
            <meta name="og:image" content={`${meta && meta.image? meta.image : "assets/img/logonow.png"}`} />
            <link itemprop="image" href={`${meta && meta.image? meta.image : "assets/img/logonow.png"}`} />
        </Helmet>
        <Navbar />
        <PageHeader headertitle={sub_services &&sub_services[0]?.service_name} subheader={sub_services &&sub_services[0]?.service_name} />
        <ServiceDetails sub_services={sub_services} ser_image={ser_image} service={service} ser_id={code}/>
        <CallToActionV1 />
        <Footer />
    </div>
}

export default Service_Details

