import React, { Component, useEffect,useState } from 'react';
import { Link } from 'react-router-dom';
import Social from '../section-components/social';
import Copyright from './copyright';
import { FooterTags } from '../apiActions'
 const Footer_v1=()=>{
  const [FooterTag,setFooterTag]=useState([])
	useEffect(()=>{

		const $ = window.$;

		let publicUrl = process.env.PUBLIC_URL + '/'
		const minscript = document.createElement("script");
		minscript.async = true;
		minscript.src = publicUrl + "assets/js/main.js";

		document.body.appendChild(minscript);

		$('.go-top').find('a').on('click', function () {

			$(".quarter-overlay").fadeIn(1);

			$(window).scrollTop(0);

			setTimeout(function () {
				$(".quarter-overlay").fadeOut(300);
			}, 800);
		});


		$(document).on('click', '.theme-btn-1 ', function () {
			$('div').removeClass('modal-backdrop');
			$('div').removeClass('show');
			$('div').removeClass('fade');
			$('body').attr("style", "");
		});

		FooterTags().then((data)=>{
		   setFooterTag(data.Response)
		})

	},[])


		let publicUrl = process.env.PUBLIC_URL + '/'
		let imgattr = "Footer logo"

		return (
			<footer className="ltn__footer-area">
				<div className="footer-top-area  section-bg-2 plr--5">
					<div className="container-fluid">
						<div className="row">
							<div className="col-xl-4 col-md-6 col-sm-6 col-12">
								<div className="footer-widget footer-about-widget">
									<div className='logoNameFooter'>
										<div className="footerLogo">
											<Link to="/"><img src={publicUrl + "assets/img/logonow.png"} alt="Logo" /></Link>
										</div>
										<div className='nameShow'>
											NOW WAY
										</div>
									</div>

									{/* <div className="footer-logo">
										<div className="site-logo">
											<img src={publicUrl + "assets/img/logonow.png"} alt="Logo" />
										</div>
										<div className='nameShow'>
											NOW WAY
										</div>
									</div> */}
									{/* <p>Lorem Ipsum is simply dummy text of the and typesetting industry. Lorem Ipsum is dummy text of the printing.</p> */}
									<div className="footer-address">
										<ul>
											<li>
											    <div className="footer-address-icon">
													<i className="icon-placeholder" />
												</div> 
												 <div className="footer-address-info">
													<p>
													NOWWAY PEC SERVICES<br/>
                                                    No.119E, Velacherry Mainroad,Guindy<br/>
                                                    Chennai - 600032
													</p>
												</div> 
											</li>
											{/* <li>
												<div className="footer-address-icon">
													<i className="icon-call" />
												</div>
												<div className="footer-address-info">
													<p>+91 8248699623</p>
												</div>
											</li> */}
											<li>
												<div className="footer-address-icon">
													<i className="icon-mail" />
												</div>
												<div className="footer-address-info">
													<p>contact@nowway.in</p>
												</div>
											</li>
										</ul>
									</div>
									{/* <div className="ltn__social-media mt-20">
						    	<Social />
				            </div> */}
								</div>
							</div>
							{/* <div className="col-xl-2 col-md-6 col-sm-6 col-12">
								<div className="footer-widget footer-menu-widget clearfix">
									<h4 className="footer-title">Company</h4>
									<div className="footer-menu go-top">
										<ul>
											<li><Link to="/#">All Products</Link></li>
										</ul>
									</div>
								</div>
							</div> */}
							<div className="col-xl-2 col-md-6 col-sm-6 col-12">
								<div className="footer-widget footer-menu-widget clearfix">
									<h4 className="footer-title">Services</h4>
									<div className="footer-menu go-top">
										<ul>
										  {FooterTag.map((data)=>{
											  return(
												<li><a href={data.url}>{data.tag}</a></li>
											  )
										  })}
											{/* <li><Link to="/checkout">Checkout</Link></li>
				                <li><Link to="/about">Terms &amp; Conditions</Link></li>
				                <li><Link to="/shop">Promotional Offers</Link></li> */}
										</ul>
									</div>
								</div>
							</div>
							<div className="col-xl-2 col-md-6 col-sm-6 col-12">
								<div className="footer-widget footer-menu-widget clearfix">
									<h4 className="footer-title">My Policies</h4>
									<div className="footer-menu go-top">
										<ul>
										<li><Link to="/terms">Terms &amp; Conditions</Link></li>
						                {/* <li><Link to="/refund">Refund Policy</Link></li> */}
						                <li><Link to="/policy">Privacy &amp; Policy</Link></li>
										<li><Link to="/aboutus">About Us</Link></li>
											{/* <li><Link to="/add-listing">Add listing</Link></li>
				                <li><Link to="/faq">FAQ</Link></li>
				                <li><Link to="/contact">Contact us</Link></li> */}
										</ul>
									</div>
								</div>
							</div>
							<div className="col-xl-4 col-md-6 col-sm-12 col-12">
								<div className="footer-widget footer-newsletter-widget">
									<h4 className="footer-title">Newsletter</h4>
									<p style={{fontWeight:"500",fontSize:"17px"}}>Subscribe to our weekly Newsletter and receive updates via email.</p>
									<div className="footer-newsletter">
										<form action="#">
											<input type="email" name="email" placeholder="Email*" />
											<div className="btn-wrapper">
												<button className="theme-btn-1 btn" type="submit"><i className="fas fa-location-arrow" /></button>
											</div>
										</form>
									</div>
									{/* <h5 className="mt-30">We Accept</h5>
									<img src={publicUrl + "assets/img/icons/payment-4.png"} alt="Payment Image" /> */}
								</div>
							</div>
						</div>
					</div>
				</div>
				<Copyright />
			</footer>
		)
	}


export default Footer_v1